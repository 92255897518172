<template>
  <div class="login-wrapper">
    <h1>接入中....</h1>
    <h1>{{ corpId }}</h1>
    <h1>{{ consoleMsg }}</h1>
  </div>
</template>

<script>
import * as dd from 'dingtalk-jsapi'
export default {
  name: 'DingTalkOrgConnect',
  data() {
    return {
      corpId: null,
      consoleMsg: null
    }
  },
  created() {
    this.corpId = this.$route.query.corpId
    dd.runtime.permission.requestAuthCode({
      corpId: this.corpId,
      onSuccess: res => {
        // {"code":"5e13122a4c013af8a2687491a4e9af13"}
        // 调用成功时回调
        this.consoleMsg = JSON.stringify(res)
      },
      onFail: err => {
        // {"errorCode":"9","errorMessage":"corpId 不合法"}
        // 调用失败时回调
        this.consoleMsg = JSON.stringify(err)
        this.$lgh.toastError(err.errorMessage)
      }
    })
  },
  methods: {}
}
</script>

<style lang="less"></style>
